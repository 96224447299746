<template>
  <div>
    <form-create :inline="true" v-model="where" :label-width="'80px'" :components="[
          {name:'form-item',props:{prop:'leave_type',label:'审核类型',type:'select',data:{ url:'/xapi/course.course/studentOptions/type/leave_types' }}},
          {name:'form-item',props:{prop:'status',label:'审核状态',type:'select',data:[{value:0,label:'待审核'},{value:1,label:'已通过'},{value:2,label:'已驳回'}]}},
        ]">
      <el-form-item class="operate" slot="after">
        <el-button type="primary" size="mini" @click="$refs.table.reset(where)">搜索</el-button>
        <el-button type="primary" size="mini" @click="$refs.table.reset(where = {})">重置</el-button>
      </el-form-item>
    </form-create>
    <com-table ref="table" :columns="[
        {prop:'headimg',label:'头像',width:'60' , align:'center',slot:'avatar'},
        {prop:'nickname',label:'学生姓名',minWidth:'140', align:'center'},
        {prop:'phone',label:'手机号',width:'120', align:'center'},
        {prop:'course_name',label:'报名课程',minWidth:'160', align:'center'},
        {prop:'create_date',label:'操作时间',width:'160', align:'center'},
        {prop:'typeStr',label:'审核类型',width:'100', align:'center',template({row}){return row.leave_typeStr||row.typeStr;}},
        {prop:'price',label:'付费',width:'100', align:'center'},
        {label:'操作',width:'160',slot:'action',fixed:'right', align:'center',},
      ]"
               :height="480"
               :data="{
                    url:'/xapi/user/applyCourseList'
                 }"
               :page-options="false"
               :total.sync="total"
               :page-size="pageSize"
               @row-click="(row)=>{$refs[row.type+'Dialog'] && $refs[row.type+'Dialog'].visibled((currentData = {...row,contact_mobile:row.contact_mobile||row.phone,reason:row.check_reason,edit_disabled:true,dialog_title:row.status === 0 ? '详情' : (row.status==1 ? '通过' : '驳回'),agree:row.status==1}) && true)}"
    >
      <template slot="action" slot-scope="{row}">
        <el-button type="danger" size="mini" @click.stop="$refs[row.type+'Dialog'] && $refs[row.type+'Dialog'].visibled((currentData = {...row,contact_mobile:row.contact_mobile||row.phone,agree:false}) && true)" :disabled="row.status!==0" v-if="row.apply_type !== 'teach_plan'">拒绝</el-button>
        <el-button type="success" size="mini" @click.stop="$refs[row.type+'Dialog'] && $refs[row.type+'Dialog'].visibled((currentData = {...row,contact_mobile:row.contact_mobile||row.phone,agree:true}) && true)" :disabled="row.status!==0">同意</el-button>
      </template>
    </com-table>

    <diy-dialog ref="StudentTxDialog" :title="currentData.dialog_title || (currentData.agree ? '通过' : '驳回')" center>
      <form-create ref="StudentTxForm" v-model="currentData" :disabled="currentData.edit_disabled===true" :components="[
          {name:'form-item',props:{prop:'nickname',disabled:true,label:'申请人'}},
          {name:'form-item',props:{prop:'leave_type',disabled:true,label:'退学方式',type:'select',data:{ url:'/xapi/course.course/studentOptions/type/leave_type' }}},
          {name:'form-item',props:{prop:'contact_mobile',disabled:true,label:'联系方式'}},
          {name:'form-item',props:{prop:'apply_reason',disabled:true,label:'申请理由'}},
          {name:'form-item',props:{prop:'create_date',disabled:true,label:'申请时间',type:'datetime'}},
          {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'},hidden:currentData.agree},
          {name:'form-item',props:{prop:'refund_price',label:'退款金额',required:true,defaultValue:currentData.refund_price,type:'number',max:currentData.price},condition:'{{leave_type}}==2||{{leave_type}}==3',slots:{append:'元'},hidden:!currentData.agree},
        ]" :label-width="'100px'">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.StudentTxDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('apply','StudentTxForm',currentData.agree ? 1 : 2,()=>{$refs.StudentTxDialog.visibled(false)})" v-if="currentData.edit_disabled!==true">确 定</el-button>
      </div>
    </diy-dialog>

    <diy-dialog ref="StudentZkDialog" :title="currentData.dialog_title || (currentData.agree ? '通过' : '驳回')" center>
      <form-create ref="StudentZkForm" v-model="currentData" :disabled="currentData.edit_disabled===true" :components="[
          {name:'form-item',props:{prop:'nickname',disabled:true,label:'申请人'}},
          {name:'form-item',props:{prop:'course_name',disabled:true,label:'从......课程'}},
          {name:'form-item',props:{prop:'transfer_course_name',disabled:true,label:'到......课程'}},
          {name:'form-item',props:{prop:'apply_reason',disabled:true,label:'转课理由'}},
          {name:'form-item',props:{prop:'create_date',disabled:true,label:'申请时间',type:'datetime'}},
        ].concat(currentData.agree?[]:[
          {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'}},
        ])" :label-width="'100px'">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.StudentZkDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('apply','StudentZkForm',currentData.agree ? 1 : 2,()=>{$refs.StudentZkDialog.visibled(false)})" v-if="currentData.edit_disabled!==true">确 定</el-button>
      </div>
    </diy-dialog>
    <div :style="$attrs.pageStyle">
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="$refs.table.handlePageChange($event)" :page-size="pageSize" :current-page="page" hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      where:{},
      page: 1,
      pageSize:10,
      total:0,
      currentData:{},
      loading:false,
    };
  },
  methods:{
    handleCommandApply(ref , status,cb){
      this.$refs[ref].validate((form , valid)=>{
        if(valid){
          this.$http.post('/xapi/user/applyCourse',{...form,id: this.currentData.id, status: status,reason:this.currentData.reason || ''})
              .then(({msg}) => {
                this.$message.success(msg || (status === 1 ?"已同意" :"已拒绝"))
                this.routeUpdate()
                this.$refs.table.reset(this.where);
                cb && cb();
              })
              .catch((err) => { })
        }
      } , true);

    }
  }
}
</script>

<style scoped>

</style>