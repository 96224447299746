var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form-create',{attrs:{"inline":true,"label-width":'80px',"components":[
        {name:'form-item',props:{prop:'leave_type',label:'审核类型',type:'select',data:{ url:'/xapi/course.course/studentOptions/type/leave_types' }}},
        {name:'form-item',props:{prop:'status',label:'审核状态',type:'select',data:[{value:0,label:'待审核'},{value:1,label:'已通过'},{value:2,label:'已驳回'}]}},
      ]},model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}},[_c('el-form-item',{staticClass:"operate",attrs:{"slot":"after"},slot:"after"},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.$refs.table.reset(_vm.where)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.$refs.table.reset(_vm.where = {})}}},[_vm._v("重置")])],1)],1),_c('com-table',{ref:"table",attrs:{"columns":[
      {prop:'headimg',label:'头像',width:'60' , align:'center',slot:'avatar'},
      {prop:'nickname',label:'学生姓名',minWidth:'140', align:'center'},
      {prop:'phone',label:'手机号',width:'120', align:'center'},
      {prop:'course_name',label:'报名课程',minWidth:'160', align:'center'},
      {prop:'create_date',label:'操作时间',width:'160', align:'center'},
      {prop:'typeStr',label:'审核类型',width:'100', align:'center',template({row}){return row.leave_typeStr||row.typeStr;}},
      {prop:'price',label:'付费',width:'100', align:'center'},
      {label:'操作',width:'160',slot:'action',fixed:'right', align:'center',},
    ],"height":480,"data":{
                  url:'/xapi/user/applyCourseList'
               },"page-options":false,"total":_vm.total,"page-size":_vm.pageSize},on:{"update:total":function($event){_vm.total=$event},"row-click":(row)=>{_vm.$refs[row.type+'Dialog'] && _vm.$refs[row.type+'Dialog'].visibled((_vm.currentData = {...row,contact_mobile:row.contact_mobile||row.phone,reason:row.check_reason,edit_disabled:true,dialog_title:row.status === 0 ? '详情' : (row.status==1 ? '通过' : '驳回'),agree:row.status==1}) && true)}},scopedSlots:_vm._u([{key:"action",fn:function({row}){return [(row.apply_type !== 'teach_plan')?_c('el-button',{attrs:{"type":"danger","size":"mini","disabled":row.status!==0},on:{"click":function($event){$event.stopPropagation();_vm.$refs[row.type+'Dialog'] && _vm.$refs[row.type+'Dialog'].visibled((_vm.currentData = {...row,contact_mobile:row.contact_mobile||row.phone,agree:false}) && true)}}},[_vm._v("拒绝")]):_vm._e(),_c('el-button',{attrs:{"type":"success","size":"mini","disabled":row.status!==0},on:{"click":function($event){$event.stopPropagation();_vm.$refs[row.type+'Dialog'] && _vm.$refs[row.type+'Dialog'].visibled((_vm.currentData = {...row,contact_mobile:row.contact_mobile||row.phone,agree:true}) && true)}}},[_vm._v("同意")])]}}])}),_c('diy-dialog',{ref:"StudentTxDialog",attrs:{"title":_vm.currentData.dialog_title || (_vm.currentData.agree ? '通过' : '驳回'),"center":""}},[_c('form-create',{ref:"StudentTxForm",attrs:{"disabled":_vm.currentData.edit_disabled===true,"components":[
        {name:'form-item',props:{prop:'nickname',disabled:true,label:'申请人'}},
        {name:'form-item',props:{prop:'leave_type',disabled:true,label:'退学方式',type:'select',data:{ url:'/xapi/course.course/studentOptions/type/leave_type' }}},
        {name:'form-item',props:{prop:'contact_mobile',disabled:true,label:'联系方式'}},
        {name:'form-item',props:{prop:'apply_reason',disabled:true,label:'申请理由'}},
        {name:'form-item',props:{prop:'create_date',disabled:true,label:'申请时间',type:'datetime'}},
        {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'},hidden:_vm.currentData.agree},
        {name:'form-item',props:{prop:'refund_price',label:'退款金额',required:true,defaultValue:_vm.currentData.refund_price,type:'number',max:_vm.currentData.price},condition:'{{leave_type}}==2||{{leave_type}}==3',slots:{append:'元'},hidden:!_vm.currentData.agree},
      ],"label-width":'100px'},model:{value:(_vm.currentData),callback:function ($$v) {_vm.currentData=$$v},expression:"currentData"}}),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticStyle:{"margin-right":"50px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$refs.StudentTxDialog.visibled(false)}}},[_vm._v("取 消")]),(_vm.currentData.edit_disabled!==true)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.handleCommand('apply','StudentTxForm',_vm.currentData.agree ? 1 : 2,()=>{_vm.$refs.StudentTxDialog.visibled(false)})}}},[_vm._v("确 定")]):_vm._e()],1)],1),_c('diy-dialog',{ref:"StudentZkDialog",attrs:{"title":_vm.currentData.dialog_title || (_vm.currentData.agree ? '通过' : '驳回'),"center":""}},[_c('form-create',{ref:"StudentZkForm",attrs:{"disabled":_vm.currentData.edit_disabled===true,"components":[
        {name:'form-item',props:{prop:'nickname',disabled:true,label:'申请人'}},
        {name:'form-item',props:{prop:'course_name',disabled:true,label:'从......课程'}},
        {name:'form-item',props:{prop:'transfer_course_name',disabled:true,label:'到......课程'}},
        {name:'form-item',props:{prop:'apply_reason',disabled:true,label:'转课理由'}},
        {name:'form-item',props:{prop:'create_date',disabled:true,label:'申请时间',type:'datetime'}},
      ].concat(_vm.currentData.agree?[]:[
        {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'}},
      ]),"label-width":'100px'},model:{value:(_vm.currentData),callback:function ($$v) {_vm.currentData=$$v},expression:"currentData"}}),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticStyle:{"margin-right":"50px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$refs.StudentZkDialog.visibled(false)}}},[_vm._v("取 消")]),(_vm.currentData.edit_disabled!==true)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.handleCommand('apply','StudentZkForm',_vm.currentData.agree ? 1 : 2,()=>{_vm.$refs.StudentZkDialog.visibled(false)})}}},[_vm._v("确 定")]):_vm._e()],1)],1),_c('div',{style:(_vm.$attrs.pageStyle)},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"page-size":_vm.pageSize,"current-page":_vm.page,"hide-on-single-page":""},on:{"current-change":function($event){return _vm.$refs.table.handlePageChange($event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }